<template>
	<div class="panel-content-wrapper">
		<div class="panel-content-container">
			<div class="support">
				<div class="row my-gutters">
					<div class="col tickets-col" :class="[(isChatOpen) ? 'chat-open' : '']">
						<TicketsList />
					</div>

					<div class="col chat-col" v-if="isChatOpen">
						<Chat />
					</div>
				</div>
			</div>
		</div>

		<NewTicket />
	</div>
</template>

<script>
import NewTicket from "./NewTicket";
import TicketsList from "./TicketsList";
import Chat from "./Chat";

export default {
	components: {
		NewTicket,
		TicketsList,
		Chat
	},
	data() {
		return {
			loading: {
				tickets: false,
				chat: false,
			},
			tickets: [],
			isChatOpen: false,
			selectedChat: null,
			selectedChatData: {},
			newTicket: {
				show: false,
			}
		}
	},
	methods: {
		getTickets() {
			/* call the api */
			this.loading.tickets = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.User.user_token);

			/* make call to server */
			this.$http.post("support/getTickets", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.tickets = result.data.data.tickets;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.tickets = false;
				})
		},
		selectChat(index) {
			this.isChatOpen = true;
			this.selectedChat = index;

			/* call the api */
			this.loading.chat = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.User.user_token);
			data.append("ticket_id", this.tickets[index].id);

			/* make call to server */
			this.$http.post("support/getTicket", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.selectedChatData = result.data.data.ticket;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.chat = false;
				})
		},
		deselectChat() {
			this.isChatOpen = false;
			this.selectedChat = null;
		},
		showNewTicket() {
			this.newTicket.show = true;
		}
	},
	created() {
		this.getTickets();
	},
	mounted() {
		this.$root.$emit('setPanelTitleText', "Tickets");
	},
}
</script>

<style lang="scss">
@import "../../../assets/styles/_variables";

.support {
	height: 100%;

	.row {
		height: 100%;

		.col {
			height: 100%;

			&.tickets-col {
				flex: 0 0 auto;
				width: 100%;

				&.chat-open {
					width: 50%;

					@include only-phone-lg {
						display: none;
					}
				}
			}

			&.chat-col {
				flex: 0 0 auto;
				width: 50%;

				@include only-phone-lg {
					width: 100%;
				}
			}
		}
	}

	.tickets-list-card {
		height: 100%;

		.vf-section-card-header {
			margin-bottom: 55px !important;
		}

		.tickets-list {
			margin: 0 -32px;
			margin-top: -32px;

			.tickets-list-table {
				border-collapse: separate;
				width: 100%;
				max-width: 100%;
				border-spacing: 0 16px;
				padding: 0 32px;

				.min-width {
					width: 1%;
					white-space: nowrap;
				}

				thead {
					position: relative;
					top: 16px;
				}

				th {
					padding: 13px 16px;
					font-weight: 600;
					font-size: 14px;
					line-height: 17px;
					letter-spacing: 0.2px;
					color: #8f90a6;
					text-align: center;
				}

				.ticket-list-row {
					margin-bottom: 16px;
					cursor: pointer;

					&:hover td {
						background-color: #fcfcfc;
					}

					&.active td {
						background-color: #0063f7;
						color: #ffffff;
					}

					td {
						padding: 13px 16px;
						border-top: 1px solid #c7c9d9;
						border-bottom: 1px solid #c7c9d9;
						vertical-align: middle;
						white-space: nowrap;
						text-align: center;

						&:first-child {
							border-left: 1px solid #c7c9d9;
							border-top-left-radius: 8px;
							border-bottom-left-radius: 8px;
						}
						&:last-child {
							border-right: 1px solid #c7c9d9;
							border-top-right-radius: 8px;
							border-bottom-right-radius: 8px;
						}
					}
				}
			}
		}
	}
}
</style>