<template>
	<div class="panel-content-wrapper">
		<div class="panel-content-container">
			<vf-loading class="mx-auto mt-3" v-if="pageLoading"></vf-loading>

			<div class="withdraw">
				<div class="withdraw-content" v-if="!pageLoading">
					<div class="withdraw-title">How much do you want to withdraw?</div>
					<div class="wallet-details">
						<div class="total-wallet-amount">
							<div>Total Wallet: </div>
							<div class="amount">{{wallets.totalWallet}}</div>
						</div>
						<div class="freezed-wallet-amount">
							<div>Freezed: </div>
							<div class="amount">{{wallets.freezedWallet}}</div>
						</div>
						<div class="available-wallet-amount">
							<div>Available: </div>
							<div class="amount">{{wallets.currentWallet}}</div>
						</div>
					</div>

					<form class="withdraw-form" @submit.prevent="submitWithdraw()">
						<vf-input id="amount-input" class="" placeholder="amount" type="tel" v-model="withdrawData.amount"></vf-input>
						<vf-input id="address-input" class="mt-3" placeholder="address" type="text" v-model="withdrawData.address"></vf-input>
						<vf-btn class="mt-3 withdraw-btn" type="primary" :loading="loading.withdraw" :disabled="withdrawData.amount == '' || withdrawData.address == ''">Withdraw</vf-btn>
					</form>
				</div>
			</div>

			<div class="panel-content withdraw-history mt-4" v-if="!pageLoading">
				<div class="withdraw-history-head">
					<h5 class="withdraw-history-title tg-heading-5">Withdraw History</h5>
				</div>

				<div class="withdraw-history-content mt-3">
					<vf-table>
						<tr>
							<th>#</th>
							<th>Amount</th>
							<th>Wallet Address</th>
							<th>Status</th>
							<th>TXID</th>
							<th style="width: 50px;"></th>
						</tr>

						<tr v-for="item in withdrawHistory" :key="item.id">
							<td>{{ item.id }}</td>
							<td>{{ item.amount }}</td>
							<td>{{ item.wallet_address }}</td>
							<td>
								<vf-badge class="bg-orange fg-white" v-if="item.status == 'pending'">
									Pending
								</vf-badge>

								<vf-badge class="bg-blue fg-white" v-if="item.status == 'ongoing'">
									Ongoing
								</vf-badge>

								<vf-badge class="bg-green fg-white" v-if="item.status == 'finished'">
									Finished
								</vf-badge>
							</td>
							<td>{{item.txid}}</td>
						</tr>

					</vf-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			loading: {
				wallets: false,
				withdrawHistory: false,
				withdraw: false,
			},
			wallets: {
				currentWallet: null,
				freezedWallet: null,
				totalWallet: null,
			},
			withdrawData: {
				amount: '',
				address: '',
			},
			withdrawHistory: null,
		}
	},
	computed:{
		pageLoading(){
			return this.loading.wallets || this.loading.withdrawHistory
		}
	},
	methods: {
		getWallets() {
			/* call the api */
			this.loading.wallets = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.User.user_token);

			/* make call to server */
			this.$http.post("users/authToken", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.wallets.currentWallet = result.data.data.user.current_wallet;
						this.wallets.freezedWallet = result.data.data.user.freezed_wallet;
						this.wallets.totalWallet = result.data.data.user.total_wallet;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.wallets = false;
				})
		},
		getWithdrawHistory() {
			/* call the api */
			this.loading.withdrawHistory = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.User.user_token);

			/* make call to server */
			this.$http.post("transactions/getWithdrawHistory", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.withdrawHistory = result.data.data.withdraw_history;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.withdrawHistory = false;
				})
		},
		submitWithdraw() {
			/* call the api */
			this.loading.withdraw = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.User.user_token);
			data.append("amount", this.withdrawData.amount);
			data.append("wallet_address", this.withdrawData.address);


			/* make call to server */
			this.$http.post("transactions/withdraw", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.withdrawData.amount = '';
						this.withdrawData.address = '';
						this.getWithdrawHistory();
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.withdraw = false;
				})
		}
	},
	created() {
		this.getWallets();
		this.getWithdrawHistory();
	},
	mounted() {
		this.$root.$emit('setPanelTitleText', "Withdraw");
	}
}
</script>

<style lang="scss">
@import "../../assets/styles/_typography";

.withdraw {
	.withdraw-content {
		width: 100%;
		max-width: 500px;
		background: #fff;
		padding: 24px;
		border-radius: 8px;
		margin: 0 auto;

		.withdraw-title {
			@extend .tg-heading-4;
			text-align: center;
		}

		.wallet-details {
			width: 100%;
			max-width: 400px;
			margin: 0 auto;
			margin-top: 32px;

			.total-wallet-amount,
			.freezed-wallet-amount,
			.available-wallet-amount {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 12px;

				.amount {
					font-weight: 600;
				}
			}
		}

		.withdraw-form {
			width: 100%;
			max-width: 500px;
			margin: 0 auto;
			margin-top: 32px;

			.withdraw-btn {
				display: block;
				margin: 0 auto;
				margin-top: 32px;
				min-width: 200px;
			}
		}
	}
}
</style>