<template>
	<div class="panel-content-wrapper">
		<div class="panel-content-container">
			<div class="row gy-4">
				<div class="col-12 col-md-6">
					<PersonalData />
				</div>

				<div class="col-12 col-md-6">
					<ChangePassword />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PersonalData from "./PersonalData";
import ChangePassword from "./ChangePassword";

export default {
	components: {
		PersonalData,
		ChangePassword,
	},
	data() {
		return {
		}
	},
	mounted() {
		this.$root.$emit('setPanelTitleText', "Settings");
	}
}
</script>

<style lang="scss">
.personal-data {
	.personal-data-avatar {
		background: none;
		width: max-content;
		margin: 0 auto;
		display: block;
		position: relative;

		.avatar {
			width: 80px;
			height: 80px;
		}

		.avatar-edit-icon {
			position: absolute;
			right: 0;
			bottom: 0;
			width: 25px;
			height: 25px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			background: #000;
			color: #fff;
			box-shadow: 0px 1px 2px 0px #4f4f4f;

			i {
				font-size: 12px;
			}
		}
	}

	.personal-data-form {
		.skills-container {
			margin-top: 24px;

			.skills-input {
				margin-top: 16px;
			}
		}
	}
}
.save-btn {
	display: block;
	margin: 0 auto;
}
</style>