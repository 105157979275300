<template>
	<div class="panel-content personal-data ">
		<div class="personal-data-head">
			<h5 class="personal-data-title tg-heading-5">Personal Data</h5>
		</div>

		<button class="personal-data-avatar mt-4" @click.prevent="$refs.profile_pic_input.click()">
			<vf-avatar size="xxl">
				<template v-if="loading.changeProfilePic">
					<vf-loading :size="20" :thickness="2" />
				</template>

				<template v-else>
					<img v-if="$store.state.User.user_profile_pic_url" :src="$store.state.User.user_profile_pic_url">
					<span v-else>{{ $store.state.User.user_name.substring(0,2) }}</span>
				</template>
			</vf-avatar>

			<div class="avatar-edit-icon">
				<i class="fa fa-pencil"></i>
			</div>
		</button>

		<input class="d-none" ref="profile_pic_input" type="file" @change="handleChangeProfilePic" />

		<vf-loading class="mx-auto my-5" v-if="isPersonalDataLoading"></vf-loading>

		<form class="personal-data-form mt-5" @submit.prevent="updatePersonalData" v-else>
			<vf-input id="name-input" class="mt-3" placeholder="Name" type="text" v-model="personalData.name"></vf-input>
			<vf-input id="github-input" class="mt-3" placeholder="Github Username" type="text" v-model="personalData.github"></vf-input>
			<vf-input id="linkedin-input" class="mt-3" placeholder="Linkedin Username" type="text" v-model="personalData.linkedin"></vf-input>
			<vf-input id="instagram-input" class="mt-3" placeholder="Instagram Username" type="text" v-model="personalData.instagram"></vf-input>
			<vf-input id="facebook-input" class="mt-3" placeholder="Facebook Username" type="text" v-model="personalData.facebook"></vf-input>

			<div class="skills-container">
				<div>Your skills:</div>
				<multiselect class="skills-input" v-model="personalData.skills" :options="skillsOptions" :multiple="true" :close-on-select="false"></multiselect>
			</div>

			<vf-btn class="mt-3 save-btn" type="primary" :loading="loading.updatePersonalData">Save Changes</vf-btn>
		</form>
	</div>
</template>

<script>
export default {
	data() {
		return {
			loading: {
				changeProfilePic: false,
				personalData: false,
				skills: false,
				updatePersonalData: false
			},
			personalData: {
				name: '',
				github: '',
				linkedin: '',
				instagram: '',
				facebook: '',
				skills: [],
			},
			skillsOptions: []
		}
	},
	computed: {
		isPersonalDataLoading(){
			if(this.loading.personalData == true) return true;
			if(this.loading.skills == true) return true;
			return false;
		}
	},
	methods: {
		handleChangeProfilePic(e) {
			this.loading.changeProfilePic = true;

			var file = e.target.files[0];

			var data = new FormData();
			data.append("token", this.$store.state.User.user_token);
			data.append('profilePic', file);

			this.$http.post('users/changeProfilePic', data)
				.then((result) => {
					if (result.data.status == "ok") {
						/* update profile pic in store */
						this.$store.commit('updateUserData', {
							profile_pic_url: result.data.data.user.profile_pic_url
						});

						/* show message */
						this.$swal({
							icon: "success",
							text: result.data.data.message,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((e) => {
					this.$swal({
						icon: "error",
						text: e,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.changeProfilePic = false;
				});
		},
		getPersonalData() {
			/* call the api */
			this.loading.personalData = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.User.user_token);

			/* make call to server */
			this.$http.post("users/authToken", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.personalData.name = result.data.data.user.name;
						this.personalData.github = result.data.data.user.github;
						this.personalData.linkedin = result.data.data.user.linkedin;
						this.personalData.instagram = result.data.data.user.instagram;
						this.personalData.facebook = result.data.data.user.facebook;
						this.personalData.skills = result.data.data.user.skills;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.personalData = false;
				})
		},
		getSkills() {
			/* call the api */
			this.loading.skills = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.User.user_token);

			/* make call to server */
			this.$http.post("general/getSkills", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.skillsOptions = result.data.data.skills;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.skills = false;
				})
		},
		updatePersonalData() {
			/* call the api */
			this.loading.updatePersonalData = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.User.user_token);
			data.append("name", this.personalData.name);

			if (this.personalData.github != '' && this.personalData.github != null) {
				data.append("github", this.personalData.github);
			}

			if (this.personalData.linkedin != '' && this.personalData.linkedin != null) {
				data.append("linkedin", this.personalData.linkedin);
			}

			if (this.personalData.instagram != '' && this.personalData.instagram != null) {
				data.append("instagram", this.personalData.instagram);
			}

			if (this.personalData.facebook != '' && this.personalData.facebook != null) {
				data.append("facebook", this.personalData.facebook);
			}

			if (this.personalData.skills != null) {
				data.append("skills", this.personalData.skills.join(","));
			} else {
				data.append("skills", '');
			}


			/* make call to server */
			this.$http.post("users/editUser", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.$swal({
							icon: "success",
							text: result.data.data.message,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});

						this.getPersonalData();

						/* update profile pic in store */
						this.$store.commit('updateUserData', {
							name: result.data.data.user.name
						});
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.updatePersonalData = false;
				})
		}
	},
	created() {
		this.getPersonalData();
		this.getSkills();
	}
}
</script>

<style>
</style>