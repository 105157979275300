const state = {
	user_id: localStorage.getItem("user_id") || '',
	user_token: localStorage.getItem("user_token") || '',
	user_name: localStorage.getItem("user_name") || '',
	user_username: localStorage.getItem("user_username") || '',
	user_email: localStorage.getItem("user_email") || '',
	user_profile_pic_url: localStorage.getItem("user_profile_pic_url") || '',
	user_current_wallet: localStorage.getItem("user_current_wallet") || '',
	user_freezed_wallet: localStorage.getItem("user_freezed_wallet") || '',
	user_total_wallet: localStorage.getItem("user_total_wallet") || '',
};


const getters = {
	isUserLoggedIn(state) {
		if (state.user_token == "" || state.user_token == null || state.user_token == undefined) {
			return false;
		} else {
			return true;
		}
	}
};

const mutations = {
	setUserData(state, userData) {
		state.user_id = userData.id;
		state.user_token = userData.token;
		state.user_name = userData.name;
		state.user_username = userData.username;
		state.user_email = userData.email;
		state.user_profile_pic_url = userData.profile_pic_url;
		state.user_current_wallet = userData.current_wallet;
		state.user_freezed_wallet = userData.freezed_wallet;
		state.user_total_wallet = userData.total_wallet;

		/* set local storage */
		if (userData.store == true) {
			if(userData.id != null) localStorage.setItem('user_id', userData.id);
			if(userData.token != null) localStorage.setItem('user_token', userData.token);
			if(userData.name != null) localStorage.setItem('user_name', userData.name);
			if(userData.username != null) localStorage.setItem('user_username', userData.username);
			if(userData.email != null) localStorage.setItem('user_email', userData.email);
			if(userData.profile_pic_url != null) localStorage.setItem('user_profile_pic_url', userData.profile_pic_url);
			if(userData.current_wallet != null) localStorage.setItem('user_current_wallet', userData.current_wallet);
			if(userData.freezed_wallet != null) localStorage.setItem('user_freezed_wallet', userData.freezed_wallet);
			if(userData.total_wallet != null) localStorage.setItem('user_total_wallet', userData.total_wallet);
		}

	},
	updateUserData(state, userData) {
		if (userData.id != undefined) state.user_id = userData.id;
		if (userData.token != undefined) state.user_token = userData.token;
		if (userData.name != undefined) state.user_name = userData.name;
		if (userData.username != undefined) state.user_username = userData.username;
		if (userData.email != undefined) state.user_email = userData.email;
		if (userData.profile_pic_url != undefined) state.user_profile_pic_url = userData.profile_pic_url;
		if (userData.current_wallet != undefined) state.user_current_wallet = userData.current_wallet;
		if (userData.freezed_wallet != undefined) state.user_freezed_wallet = userData.freezed_wallet;
		if (userData.total_wallet != undefined) state.user_total_wallet = userData.total_wallet;

		/* set local storage*/
		if (localStorage.getItem("user_id") != undefined) {
			if (userData.id != undefined) localStorage.setItem('user_id', userData.id);
			if (userData.token != undefined) localStorage.setItem('user_token', userData.token);
			if (userData.name != undefined) localStorage.setItem('user_name', userData.name);
			if (userData.username != undefined) localStorage.setItem('user_username', userData.username);
			if (userData.email != undefined) localStorage.setItem('user_email', userData.email);
			if (userData.profile_pic_url != undefined) localStorage.setItem('user_profile_pic_url', userData.profile_pic_url);
			if (userData.current_wallet != undefined) localStorage.setItem('user_current_wallet', userData.current_wallet);
			if (userData.freezed_wallet != undefined) localStorage.setItem('user_freezed_wallet', userData.freezed_wallet);
			if (userData.total_wallet != undefined) localStorage.setItem('user_total_wallet', userData.total_wallet);
		}
	},
	unsetUserData(state) {
		state.id = '';
		state.user_token = '';
		state.user_name = '';
		state.user_username = '';
		state.user_email = '';
		state.user_profile_pic_url = '';
		state.user_current_wallet = '';
		state.user_freezed_wallet = '';
		state.user_total_wallet = '';


		/* set local storage */
		localStorage.removeItem('user_id');
		localStorage.removeItem('user_token');
		localStorage.removeItem('user_name');
		localStorage.removeItem('user_username');
		localStorage.removeItem('user_email');
		localStorage.removeItem('user_profile_pic_url');
		localStorage.removeItem('user_current_wallet');
		localStorage.removeItem('user_freezed_wallet');
		localStorage.removeItem('user_total_wallet');
	},

};

const actions = {
	loginUser(context, creds) {
		return new Promise((resolve, reject) => {
			/* make data */
			var data = new FormData();
			data.append("email", creds.email);
			data.append("password", creds.password);

			/* make call to server */
			this._vm.$http.post("users/loginUser", data).then((result) => {
				if (result.data.status == "ok") {
					context.commit('setUserData', {
						id: result.data.data.user.id,
						token: result.data.data.user.token,
						name: result.data.data.user.name,
						username: result.data.data.user.username,
						email: result.data.data.user.email,
						profile_pic: result.data.data.user.profile_pic,
						profile_pic_url: result.data.data.user.profile_pic_url,
						current_wallet: result.data.data.user.current_wallet,
						freezed_wallet: result.data.data.user.freezed_wallet,
						total_wallet: result.data.data.user.total_wallet,
						store: creds.store == true
					});
					resolve(result.data.data);
				} else {
					reject(result.data.error);
				}
			}).catch(function (error) {
				reject(error);
			});
		})
	},
	logoutUser(context) {
		context.commit('unsetUserData');
	},
	updateUserData(context) {
		return new Promise((resolve, reject) => {
			/* make data */
			var data = new FormData();
			data.append("token", context.state.user_token);

			/* make call to server */
			this._vm.$http.post("user/users/authToken", data).then((result) => {
				if (result.data.status == "ok") {
					context.commit('setUserData', {
						id: result.data.data.user.id,
						token: result.data.data.user.token,
						name: result.data.data.user.name,
						username: result.data.data.user.username,
						email: result.data.data.user.email,
						profile_pic: result.data.data.user.profile_pic,
						profile_pic_url: result.data.data.user.profile_pic_url,
						current_wallet: result.data.data.user.current_wallet,
						freezed_wallet: result.data.data.user.freezed_wallet,
						total_wallet: result.data.data.user.total_wallet,
					});
					resolve(result.data.data);
				} else {
					reject(result.data.error);
				}
			}).catch(function (error) {
				reject(error);
			});
		})
	}
};

export default {
	state,
	getters,
	mutations,
	actions
};
