import Vue from 'vue'

/* global */
import VfLoading from './global/VfLoading.vue'
import VfAvatar from './global/VfAvatar.vue'
import VfAvatarStack from './global/VfAvatarStack.vue'
import VfAvatarWithName from './global/VfAvatarWithName.vue'
import VfSpacer from './global/VfSpacer.vue'
import VfBadge from './global/VfBadge.vue'
import VfBtn from './global/VfBtn.vue'
import VfIcontext from './global/VfIcontext.vue'
import VfCard from './global/VfCard.vue'
import VfMicrocard from './global/VfMicrocard.vue'
import VfMetric from './global/VfMetric.vue'
import VfProgressbar from './global/VfProgressbar.vue'
import VfTable from './global/VfTable.vue'
import VfSectionCard from './global/VfSectionCard.vue'
import VfModal from './global/VfModal.vue'
import VfUploadLoading from './global/VfUploadLoading.vue'
Vue.component('vf-loading', VfLoading)
Vue.component('vf-avatar', VfAvatar)
Vue.component('vf-avatar-stack', VfAvatarStack)
Vue.component('vf-avatar-with-name', VfAvatarWithName)
Vue.component('vf-spacer', VfSpacer)
Vue.component('vf-badge', VfBadge)
Vue.component('vf-btn', VfBtn)
Vue.component('vf-icontext', VfIcontext)
Vue.component('vf-card', VfCard)
Vue.component('vf-micro-card', VfMicrocard)
Vue.component('vf-metric', VfMetric)
Vue.component('vf-progress-bar', VfProgressbar)
Vue.component('vf-table', VfTable)
Vue.component('vf-section-card', VfSectionCard)
Vue.component('vf-modal', VfModal)
Vue.component('vf-upload-loading', VfUploadLoading)

/* inputs */
import VfInput from './inputs/VfInput.vue'
import VfTextarea from './inputs/VfTextarea.vue'
import VfSelectInput from './inputs/VfSelectInput.vue'
import VfCheckbox from './inputs/VfCheckbox.vue'
import VfAuthCode from './inputs/VfAuthCode.vue'
Vue.component('vf-input', VfInput)
Vue.component('vf-textarea', VfTextarea)
Vue.component('vf-select-input', VfSelectInput)
Vue.component('vf-checkbox', VfCheckbox)
Vue.component('vf-auth-code', VfAuthCode)


/* btns */


/* chat */
import VfChatHeader from './chat/VfChatHeader'
import VfChatMessages from './chat/VfChatMessages'
import VfChatFooter from './chat/VfChatFooter'
import VfChat from './chat/VfChat'
Vue.component('vf-chat-header', VfChatHeader)
Vue.component('vf-chat-messages', VfChatMessages)
Vue.component('vf-chat-footer', VfChatFooter)
Vue.component('vf-chat', VfChat)

/* panel */
import VfSidebar from './panel/VfSidebar.vue'
Vue.component('vf-sidebar', VfSidebar)
