import { render, staticRenderFns } from "./VfTextarea.vue?vue&type=template&id=43617dc0&scoped=true&"
import script from "./VfTextarea.vue?vue&type=script&lang=js&"
export * from "./VfTextarea.vue?vue&type=script&lang=js&"
import style0 from "./VfTextarea.vue?vue&type=style&index=0&id=43617dc0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43617dc0",
  null
  
)

export default component.exports