import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './components'

/* vue select */
import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)

/* global */
import global from './scripts/global' 
Vue.prototype.$global = global

/* axios */
import axios from 'axios'
axios.defaults.baseURL = global.base_url + 'index.php/';
Vue.prototype.$http = axios

/* sweet alert */
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css'; // If you don't need the styles, do not connect
Vue.use(VueSweetalert2);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
