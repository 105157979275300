<template>
	<div class="panel-content-wrapper dashboard-wrapper">
		<div class="panel-content-container dashboard">
			<vf-loading class="mx-auto mt-3" v-if="pageLoading" />

			<SmallBoxes v-if="!pageLoading" />

			<div class="panel-content projects-panel mt-4" v-if="!newProject.show && !editProject.show && !pageLoading">
				<div class="projects-panel-head">
					<h5 class="projects-panel-title tg-heading-5">Your Projects</h5>
					<vf-btn class="ms-2" type="primary" @click="showNewProject">New Project <i class="fas fa-plus ms-2"></i></vf-btn>
				</div>

				<div class="projects-panel-content mt-3">
					<vf-table>
						<tr>
							<th>#</th>
							<th>Project Title</th>
							<th>Project Worth</th>
							<th>Status</th>
							<th>Create Date</th>
							<th style="width: 50px;"></th>
						</tr>

						<tr v-for="item in projects" :key="item.id">
							<td>{{item.id}}</td>
							<td>{{item.title}}</td>
							<td>{{item.worth}}</td>
							<td>
								<vf-badge class="bg-orange fg-white" v-if="item.status == 'pending'">
									Pending
								</vf-badge>

								<vf-badge class="bg-green fg-white" v-else-if="item.status == 'accepted'">
									Accepted
								</vf-badge>

								<vf-badge class="bg-red fg-white" v-else-if="item.status == 'rejected'">
									Rejected
								</vf-badge>

								<vf-badge class="bg-blue fg-white" v-else-if="item.status == 'finished'">
									Finished
								</vf-badge>
							</td>
							<td>{{item.human_created_at}}</td>
							<td>
								<vf-btn class="" type="outline" @click="showEditProject(item)">Edit <i class="fas fa-pencil ms-2"></i></vf-btn>
							</td>
						</tr>

					</vf-table>
				</div>
			</div>

			<NewProject v-if="newProject.show && !pageLoading" />
			<EditProject v-if="editProject.show && !pageLoading" />
		</div>

	</div>
</template>

<script>
import SmallBoxes from "./SmallBoxes"
import NewProject from "./NewProject"
import EditProject from "./EditProject"

export default {
	components: {
		SmallBoxes,
		NewProject,
		EditProject,
	},
	data() {
		return {
			loading: {
				dashboard: false,
				projects: false,
			},
			dashboard: {
				total_projects_count: null,
				total_projects_worth: null,
				total_wallet: null,
				freezed_wallet: null,
				total_available_coins: null,
			},
			projects: [],
			newProject: {
				show: false
			},
			editProject: {
				show: false,
				projectData: {
					id: '',
					title: '',
					worth: '',
					supportPercentage: '',
					description: '',
				}
			}
		}
	},
	computed: {
		pageLoading() {
			return this.loading.dashboard || this.loading.projects;
		}
	},
	methods: {
		showNewProject() {
			this.newProject.show = true
		},
		showEditProject(project) {
			/* set project data */
			this.editProject.projectData.id = project.id;
			this.editProject.projectData.title = project.title;
			this.editProject.projectData.worth = project.worth;
			this.editProject.projectData.supportPercentage = project.support_percentage;
			this.editProject.projectData.description = project.description;

			this.editProject.show = true
		},
		getDashboard() {
			/* call the api */
			this.loading.dashboard = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.User.user_token);

			/* make call to server */
			this.$http.post("general/dashboard", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.dashboard = result.data.data
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.dashboard = false;
				})
		},
		getProjects() {
			/* call the api */
			this.loading.projects = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.User.user_token);

			/* make call to server */
			this.$http.post("projects/getUserProjects", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.projects = result.data.data.projects
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.projects = false;
				})
		}
	},
	created() {
		this.getDashboard();
		this.getProjects();
	},
	mounted() {
		this.$root.$emit('setPanelTitleText', "Dashboard");
	}
}
</script>

<style lang="scss">
.dashboard-wrapper {
	.total-available-coins {
		background: #000;
		padding: 10px 16px;
		border-radius: 6px;
		color: white;
	}

	.dashboard {
		.projects-panel {
			.projects-panel-head {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}
	}
}
</style>