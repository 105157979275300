<template>
	<div class="panel-content-wrapper">
		<div class="panel-content-container">
			<vf-loading class="mx-auto mt-3" v-if="loading.transactions"></vf-loading>

			<div class="panel-content transactions" v-else>
				<div class="transactions-content">
					<vf-table>
						<tr>
							<th>#</th>
							<th>Wallet</th>
							<th>Amount</th>
							<th>Description</th>
							<th>Date</th>
						</tr>

						<tr v-for="(item) in transactions" :key="item.id" :class="{'bg-red-50' : item.amount < 0 ,  'bg-green-50' : item.amount > 0}">
							<td>{{item.id}}</td>
							<td>
								<span v-if="item.wallet == 'current_wallet'">Current Wallet</span>
								<span v-else-if="item.wallet == 'freezed_wallet'">Freezed Wallet</span>
							</td>
							<td>{{item.amount}}</td>
							<td>{{item.description}}</td>
							<td>{{item.human_created_at}}</td>
						</tr>

					</vf-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components: {
	},
	data() {
		return {
			loading: {
				transactions: false,
			},
			transactions: null,
		}
	},
	methods: {
		getTransactions() {
			/* call the api */
			this.loading.transactions = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.User.user_token);

			/* make call to server */
			this.$http.post("transactions/getTransactionHistory", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.transactions = result.data.data.transactions;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.transactions = false;
				})
		}
	},
	created() {
		this.getTransactions();
	},
	mounted() {
		this.$root.$emit('setPanelTitleText', "Transactions");
	}
}
</script>

<style lang="scss">
</style>