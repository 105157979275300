import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login/Login.vue'
import Register from '../views/Register/Register.vue'
import LayoutPanel from '../views/Layouts/LayoutPanel.vue'
import Logout from "../views/Logout"

/* panel */
import Dashboard from '../views/Panel/Dashboard/Dashboard.vue'
import Transactions from '../views/Panel/Transactions.vue'
import Withdraw from '../views/Panel/Withdraw.vue'
import Settings from '../views/Panel/Settings/Settings.vue'
import Tickets from '../views/Panel/Support/Tickets.vue'
import Calculator from '../views/Panel/Calculator.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/panel',
    name: 'panel',
    component: LayoutPanel,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: 'transactions',
        name: 'Transactions',
        component: Transactions
      },
      {
        path: 'withdraw',
        name: 'Withdraw',
        component: Withdraw
      },
      {
        path: 'chat',
        name: 'Tickets',
        component: Tickets
      },
      {
        path: 'calculator',
        name: 'Calculator',
        component: Calculator
      },
      {
        path: 'settings',
        name: 'Settings',
        component: Settings
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
