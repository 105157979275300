<template>
	<div></div>
</template>

<script>
export default {
	name: "Logout",
	data: () => ({

	}),
	created(){
		this.$store.dispatch('logoutUser');
		this.$router.push('/login')
	}
};
</script>

<style>
</style>