<template>
	<vf-modal class="new-ticket" v-model="$parent.newTicket.show" ref="childComponent">
		<div class="new-ticket-header">
			<button class="close-btn" @click="$parent.newTicket.show = false">
				<i class="fas fa-times"></i>
			</button>
			<div class="new-ticket-title">New Ticket</div>
		</div>

		<div class="new-ticket-content">
			<form class="new-ticket-form" @submit.prevent="creaetNewTicket">
				<div class="form-row">
					<vf-input label="Title" id="new-ticket-title" v-model="newTicketData.title" />
				</div>

				<div class="form-row">
					<vf-select-input label="Project (optional)" placeholder="Select Project" :selectOptions="userProjects" v-model="newTicketData.project" />
				</div>

				<div class="form-row">
					<vf-textarea label="Message" id="new-ticket-desc" placeholder="" v-model="newTicketData.message" />
				</div>

				<div class="form-row">
					<vf-btn class="new-ticket-submit-btn" type="secondary" @click.prevent="$refs.new_ticket_file_input.click()">
						<i class="fas fa-plus btn-icon"></i> Attach Files
					</vf-btn>
					<input class="d-none" ref="new_ticket_file_input" type="file" @change="handleNewTicketFileUpload" />
				</div>

				<div class="uploads">
					<div class="form-row" v-for="(item , i) in newTicketData.attachments" :key="i">
						<vf-upload-loading :title="item.name" :value="item.percentCompleted" @delete="handleNewTicketFileDelete(i)" />
					</div>
				</div>

				<div class="form-btns d-flex">
					<vf-btn class="new-ticket-submit-btn" type="primary" :disabled="!isDataFilled" :loading="loading.createNewTicket">
						Submit
					</vf-btn>
					<vf-btn class="new-ticket-cancel-btn" type="outline" @click.prevent="$parent.newTicket.show = false">
						Cancel
					</vf-btn>
				</div>
			</form>
		</div>
	</vf-modal>
</template>

<script>
export default {
	data() {
		return {
			loading: {
				userProjects: false,
				createNewTicket: false,
			},
			newTicketData: {
				title: '',
				project: null,
				message: '',
				attachments: [],
			},
			userProjects: [],
		}
	},
	computed:{
		isDataFilled(){
			if(this.newTicketData.title == '') return false;
			if(this.newTicketData.message == '') return false;
			return true;
		}
	},
	methods: {
		getUserProjects() {
			/* call the api */
			this.loading.userProjects = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.User.user_token);

			/* make call to server */
			this.$http.post("projects/getUserProjects", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.userProjects = [];
						result.data.data.projects.forEach((project) => {
							this.userProjects.push({ title: project.title, value: project.id })
						});
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.userProjects = false;
				})
		},
		handleNewTicketFileUpload(e) {
			var file = e.target.files[0];

			var fileIndex = this.newTicketData.attachments.push({
				name: file.name,
				size: file.size,
				type: file.type,
				finished: false,
				percentCompleted: 0,
				file_id: null,
			}) - 1;

			var data = new FormData();
			data.append("token", this.$store.state.User.user_token);
			data.append('section', 'tickets');
			data.append('file', file);

			var config = {
				onUploadProgress: (progressEvent) => {
					var percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100);
					this.newTicketData.attachments[fileIndex].percentCompleted = percentCompleted;
				}
			};

			this.$http.post('files/uploadFile', data, config)
				.then((response) => {
					var responseData = response.data.data;
					this.newTicketData.attachments[fileIndex].file_id = responseData.file.id;
					this.newTicketData.attachments[fileIndex].finished = true;
				})
				.catch((e) => {
					/* remove the file from ticket */
					this.newTicketData.attachments.splice(fileIndex, 1);
				});
		},
		handleNewTicketFileDelete(index) {
			this.newTicketData.attachments.splice(index, 1);
		},
		creaetNewTicket() {
			/* call the api */
			this.loading.createNewTicket = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.User.user_token);
			data.append("title", this.newTicketData.title);
			data.append("message", this.newTicketData.message);

			if(this.newTicketData.project != null){
				data.append("project_id", this.newTicketData.project.value);
			}

			/* add files */
			var files = [];
			this.newTicketData.attachments.forEach(item => {
				if (item.finished == true && item.file_id != null) {
					files.push(item.file_id);
				}
			})

			if (files.length > 0) {
				data.append("attachments", files.join(","));
			}

			/* make call to server */
			this.$http.post("support/createTicket", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.resetForm();
						this.$parent.newTicket.show = false;
						this.$parent.getTickets();
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.createNewTicket = false;
				})
		},
		resetForm() {
			this.newTicketData = {
				title: '',
				project: null,
				message: '',
				attachments: [],
			}
		}
	},
	watch: {
		'$parent.newTicket.show': function (newVal) {
			if (newVal == true) {
				this.newTicketData.project = null;
				this.getUserProjects();
			}
		}
	}
}
</script>

<style lang="scss">
.new-ticket {
	.new-ticket-header {
		display: flex;
		align-items: center;

		.close-btn {
			background: none;
			margin-right: 32px;

			i {
				font-size: 16px;
			}
		}

		.new-ticket-title {
			font-weight: bold;
			font-size: 20px;
			line-height: 24px;
			letter-spacing: 0.2px;
			color: #1c1c28;
		}
	}

	.new-ticket-content {
		margin-top: 24px;

		.new-ticket-form {
			margin-top: 28px;
			width: 100%;
			max-width: 450px;

			.form-row {
				margin-bottom: 16px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			.form-btns {
				margin-top: 32px;
			}

			.new-ticket-send-btn {
				margin-top: 28px;
			}

			.new-ticket-cancel-btn {
				margin-left: 16px;
				border-color: #4040f2;
				color: #4040f2;
			}
		}
	}
}
</style>