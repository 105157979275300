<template>
	<div class="panel-content panel-card mt-4">
		<div class="panel-head">
			<h5 class="tg-heading-5">Edit Project</h5>
		</div>

		<div class="edit-project-content-wrapper mt-3">
			<div class="edit-project-content">
				<form class="edit-project-form" @submit.prevent="editProject">
					<div class="form-row">
						<vf-input label="Project Title" id="edit-project-title" v-model="$parent.editProject.projectData.title" />
					</div>
					<div class="form-row">
						<vf-input type="tel" label="Project Worth" id="edit-project-worth" v-model="$parent.editProject.projectData.worth" />
					</div>
					<div class="form-row">
						<vf-input type="text" label="Support Percentage" id="edit-project-support-percentage" v-model="$parent.editProject.projectData.supportPercentage" />
					</div>
					<div class="form-row">
						<vf-textarea label="Project Description" id="edit-project-desc" v-model="$parent.editProject.projectData.description" />
					</div>
					<div class="edit-project-btns d-flex">
						<vf-btn class="edit-project-edit-btn" type="primary" :loading="loading.editProject">
							Edit Project
						</vf-btn>
						<vf-btn class="edit-project-cancel-btn" type="outline" @click.prevent="$parent.editProject.show = false">
							Cancel
						</vf-btn>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components: {
	},
	data() {
		return {
			loading: {
				editProject: false,
			},
			projectStatuses: [
				{ title: 'Pending', value: 'pending' },
				{ title: 'Finished', value: 'finished' },
			],
		}
	},
	methods: {
		editProject() {
			/* call the api */
			this.loading.editProject = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.User.user_token);
			data.append("project_id", this.$parent.editProject.projectData.id);
			data.append("title", this.$parent.editProject.projectData.title);
			data.append("worth", this.$parent.editProject.projectData.worth);
			data.append("supportPercentage", this.$parent.editProject.projectData.supportPercentage);
			data.append("description", this.$parent.editProject.projectData.description);


			/* make call to server */
			this.$http.post("projects/editProject", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.$parent.editProject.show = false;
						this.$parent.getProjects();
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.editProject = false;
				})
		}
	}
}
</script>

<style lang="scss">
.edit-project-content-wrapper {
	.edit-project-content {
		width: 100%;
		max-width: 600px;

		.edit-project-form {
			margin-top: 28px;
			width: 100%;
			max-width: 450px;

			.form-row {
				margin-bottom: 16px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			.edit-project-btns {
				margin-top: 32px;

				.edit-project-edit-btn {
					margin-right: 24px;
				}
			}
		}
	}
}
</style>