<template>
	<div class="panel-content panel-card mt-4">
		<div class="panel-head">
			<h5 class="tg-heading-5">New Project</h5>
		</div>

		<div class="new-project-content-wrapper mt-3">
			<div class="new-project-content">
				<form class="new-project-form">
					<div class="form-row">
						<vf-input label="Project Title" id="new-project-title" v-model="newProject.title" />
					</div>

					<div class="form-row">
						<vf-input type="tel" label="Project Worth (BIXB)" id="new-project-worth" v-model="newProject.worth" />
					</div>

					<div class="form-row">
						<vf-input type="text" label="Support Percentage %" id="new-project-support-percentage" v-model="newProject.supportPercentage"/>
					</div>

					<div class="form-row">
						<vf-textarea label="Project Description" id="new-project-desc" v-model="newProject.description" />
					</div>

					<div class="form-row">
						<vf-btn class="new-project-submit-btn" type="secondary" @click.prevent="$refs.new_project_file_input.click()">
							<i class="fas fa-plus btn-icon"></i> Attach Files
						</vf-btn>
						<input class="d-none" ref="new_project_file_input" type="file" @change="handleNewProjectFileUpload" />
					</div>

					<div class="uploads">
						<div class="form-row" v-for="(item , i) in newProject.attachments" :key="i">
							<vf-upload-loading :title="item.name" :value="item.percentCompleted" @delete="handleNewProjectFileDelete(i)" />
						</div>
					</div>

					<div class="new-project-btns d-flex">
						<vf-btn class="new-project-create-btn" type="primary" @click.prevent="createProject()" :disabled="!isDataFilled" :loading="loading.newProject">
							Create Project
						</vf-btn>
						<vf-btn class="new-project-cancel-btn" type="outline" @click.prevent="$parent.newProject.show = false">
							Cancel
						</vf-btn>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components: {
	},
	data() {
		return {
			loading:{
				newProject: false,
			},
			projectStatuses: [
				{ title: 'Pending', value: 'pending' },
				{ title: 'Accepted', value: 'accepted' },
				{ title: 'Rejected', value: 'rejected' },
				{ title: 'Finished', value: 'finished' },
			],
			newProject: {
				title: '',
				worth: '',
				supportPercentage: '',
				description: '',
				attachments: [],
			}
		}
	},
	computed:{
		isDataFilled(){
			if (this.newProject.title == '') return false;
			if (this.newProject.worth == '') return false;
			if (this.newProject.supportPercentage == '') return false;
			if (this.newProject.description == '') return false;
			return true;
		}
	},
	methods: {
		handleNewProjectFileUpload(e) {
			var file = e.target.files[0];

			var fileIndex = this.newProject.attachments.push({
				name: file.name,
				size: file.size,
				type: file.type,
				finished: false,
				percentCompleted: 0,
				file_id: null,
			}) - 1;

			var data = new FormData();
			data.append("token", this.$store.state.User.user_token);
			data.append('section', 'projects');
			data.append('file', file);

			var config = {
				onUploadProgress: (progressEvent) => {
					var percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100);
					this.newProject.attachments[fileIndex].percentCompleted = percentCompleted;
				}
			};

			this.$http.post('files/uploadFile', data, config)
				.then((response) => {
					var responseData = response.data.data;
					this.newProject.attachments[fileIndex].file_id = responseData.file.id;
					this.newProject.attachments[fileIndex].finished = true;
				})
				.catch((e) => {
					/* remove the file from project */
					this.newProject.attachments.splice(fileIndex, 1);
				});
		},
		handleNewProjectFileDelete(index) {
			this.newProject.attachments.splice(index, 1);
		},
		createProject() {
			/* call the api */
			this.loading.newProject = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.User.user_token);
			data.append("title", this.newProject.title);
			data.append("worth", this.newProject.worth);
			data.append("supportPercentage", this.newProject.supportPercentage);
			data.append("description", this.newProject.description);

			/* add files */
			var files = [];
			this.newProject.attachments.forEach(item => {
				if(item.finished == true && item.file_id != null){
					files.push(item.file_id);
				}
			})
			
			if(files.length > 0){
				data.append("files", files.join(","));
			}

			/* make call to server */
			this.$http.post("projects/createProject", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.$parent.newProject.show = false;
						this.$parent.getProjects();
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.newProject = false;
				})
		},
		resetForm() {
			this.newProject = {
				title: '',
				worth: '',
				supportPercentage: '',
				description: '',
				attachments: [],
			}
		}
	}
}
</script>

<style lang="scss">
.new-project-content-wrapper {
	.new-project-content {
		width: 100%;
		max-width: 600px;

		.new-project-form {
			margin-top: 28px;
			width: 100%;
			max-width: 450px;

			.form-row {
				margin-bottom: 16px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			.new-project-btns {
				margin-top: 32px;

				.new-project-create-btn {
					margin-right: 24px;
				}
			}
		}
	}
}
</style>