<template>
	<div class="panel-content change-password">
		<div class="change-password-head">
			<h5 class="change-password-title tg-heading-5">Change Password</h5>
		</div>

		<form class="change-password-form" @submit.prevent="changePassword">
			<vf-input id="old-pass-input" class="mt-3" placeholder="Old Password" type="password" v-model="changePasswordData.oldPass"></vf-input>
			<vf-input id="new-pass-input" class="mt-3" placeholder="New Password" type="password" v-model="changePasswordData.newPass"></vf-input>
			<vf-input id="confirm-pass-input" class="mt-3" placeholder="Confirm Password" type="password" v-model="changePasswordData.newPassRe"></vf-input>
			<vf-btn class="mt-3 save-btn" type="primary" :disabled="!isDataFilled" :loading="loading.changePassword">Change Password</vf-btn>
		</form>
	</div>
</template>

<script>
export default {
	data() {
		return {
			loading: {
				changePassword: false,
			},
			changePasswordData: {
				oldPass: '',
				newPass: '',
				newPassRe: ''
			}
		}
	},
	computed: {
		isDataFilled() {
			if (this.changePasswordData.oldPass == '') return false;
			if (this.changePasswordData.newPass == '') return false;
			if (this.changePasswordData.newPassRe == '') return false;
			return true;
		}
	},
	methods: {
		changePassword() {
			/* call the api */
			this.loading.changePassword = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.User.user_token);
			data.append("oldPassword", this.changePasswordData.oldPass);
			data.append("newPassword", this.changePasswordData.newPass);
			data.append("newPasswordRe", this.changePasswordData.newPassRe);

			/* make call to server */
			this.$http.post("users/changePassword", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.resetForm();
						
						this.$swal({
							icon: "success",
							text: result.data.data.message,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.changePassword = false;
				})
		},
		resetForm(){
			this.changePasswordData = {
				oldPass: '',
				newPass: '',
				newPassRe: ''
			}
		}
	}
}
</script>

<style>
</style>