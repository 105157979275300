<template>
	<vf-section-card class="tickets-list-card">
		<template v-slot:header-left>
			<div class="vf-section-card-header-title">Tickets Lists</div>
			<vf-loading class="ms-3" :size="20" :thickness="2" v-if="$parent.loading.tickets" />
		</template>

		<template v-slot:header-right>
			<vf-btn type="primary" @click="$parent.showNewTicket">
				<i class="fas fa-plus btn-icon"></i> Create New Ticket
			</vf-btn>
		</template>

		<div class="tickets-list">
			<table class="tickets-list-table">
				<thead>
					<tr>
						<th class="min-width">#</th>
						<th>Title</th>
						<th>Project</th>
						<th>Date</th>
						<th class="min-width">Status</th>
					</tr>
				</thead>
				<tbody>
					<tr class="ticket-list-row" v-for="(item , i) in $parent.tickets" :class="[(i == $parent.selectedChat) ? 'active' : '']" :key="i" @click="$parent.selectChat(i)">
						<td>{{item.id}}</td>
						<td>{{item.title}}</td>
						<td>
							<template v-if="item.project_title != null">
								{{item.project_title}}
							</template>

							<template v-else>
								-
							</template>
						</td>
						<td>{{item.human_created_at}}</td>
						<td>
							<vf-badge class="bg-green fg-white d-block text-center" v-if="item.status == 'open'">
								Open
							</vf-badge>

							<vf-badge class="bg-blue fg-white d-block text-center" v-else-if="item.status == 'admin_answered'">
								Support Answered
							</vf-badge>

							<vf-badge class="bg-cyan fg-white d-block text-center" v-else-if="item.status == 'user_answered'">
								User Answered
							</vf-badge>

							<vf-badge class="bg-grey fg-white d-block text-center" v-else-if="item.status == 'closed'">
								Closed
							</vf-badge>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</vf-section-card>
</template>

<script>
export default {
	data() {
		return {

		}
	},
}
</script>

<style>
</style>