<template>
	<div class="register-wrapper">
		<div class="register-content-wrapper">
			<div class="register-content">
				<h4 class="register-title">Register</h4>
				<div class="register-desc">Create an account.</div>

				<form class="register-form" @submit.prevent="registerUser()">
					<vf-input id="email-input" class="" placeholder="Email Address" type="email" v-model="registerData.email"></vf-input>
					<vf-input id="name-input" class="mt-4" placeholder="Name" v-model="registerData.name"></vf-input>
					<vf-input id="password-input" class="mt-4" placeholder="Password" type="password" v-model="registerData.password"></vf-input>

					<div class="skills-container">
						<div>Your skills:</div>
						<multiselect class="skills-input" v-model="registerData.skills" :options="skillsOptions" :multiple="true" :close-on-select="false" :loading="loading.skills"></multiselect>
					</div>

					<div class="register-button mt-4">
						<button id="register-btn">
							<vf-loading class="mx-auto" color="#fff" :size="20" :thickness="2" v-if="loading.register" />
							<span v-else>Register</span>
						</button>
					</div>

					<div class="register-create-account mt-5">
						<span>Already have an account?</span>
						<button @click="$router.push('/login')">Login</button>
					</div>
				</form>
			</div>
		</div>

		<div class="register-spacefiller">
			<img class="register-logo" src="../../assets/pics/logo.png">
		</div>
	</div>
</template>

<script>
export default {
	components: {

	},
	data() {
		return {
			loading: {
				register: false,
				skills: false,
			},
			registerData: {
				email: null,
				name: null,
				password: null,
				skills: null
			},
			skillsOptions: []
		}
	},
	methods: {
		getSkills() {
			/* call the api */
			this.loading.skills = true;

			/* make data */
			var data = new FormData();

			/* make call to server */
			this.$http.post("general/getSkills", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.skillsOptions = result.data.data.skills;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
					});
				})
				.finally(() => {
					this.loading.skills = false;
				})
		},
		registerUser() {
			/* call the api */
			this.loading.register = true;

			/* make data */
			var data = new FormData();
			data.append("email", this.registerData.email);
			data.append("name", this.registerData.name);
			data.append("password", this.registerData.password);
			data.append("skills", this.skillsOptions.join(","));


			/* make call to server */
			this.$http.post("users/registerUser", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.$swal({
							icon: "success",
							text: "Your account has been registered successfully!",
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
						this.$router.push('/login');
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.register = false;
				})
		}
	},
	created() {
		if (this.$store.getters.isUserLoggedIn) {
			this.$router.push('/panel/dashboard');
			return;
		}

		this.getSkills();
	}
}
</script>

<style lang="scss">
@import "../../assets/styles/_variables.scss";

.register-wrapper {
	display: flex;
	height: 100%;

	.register-spacefiller {
		width: 700px;
		height: 100%;
		background: black;
		position: relative;
		border-radius: 50px 0px 0px 50px;
		background-image: url("../../assets/pics/login-background.jpg");
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		@include only-phone-lg {
			display: none;
		}

		.register-logo {
			position: absolute;
			width: 70px;
			top: 100px;
			right: 100px;
		}
	}

	.register-content-wrapper {
		flex: 1;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		@include only-phone-lg {
			padding: 0px 26px;
		}

		.register-content {
			width: 100%;
			max-width: 500px;
			display: flex;
			flex-direction: column;
			align-items: center;

			.register-title {
				font-size: 32px;
				line-height: 39px;
				letter-spacing: 0.2px;
				color: #000000;
				font-weight: 700;
				width: 100%;
			}

			.register-desc {
				font-size: 14px;
				line-height: 17px;
				letter-spacing: 0.2px;
				color: #8f90a6;
				margin-bottom: 40px;
				width: 100%;
				margin-top: 12px;
			}

			.register-form {
				width: 100%;

				.skills-container {
					margin-top: 24px;

					.skills-input {
						margin-top: 16px;
					}
				}

				.register-bottom {
					display: flex;
					align-items: center;

					.register-remember {
					}

					.register-forget {
						margin-left: auto;

						#forget-btn {
							background: none;
						}
					}
				}

				#register-btn {
					background: #000000;
					color: #ffffff;
					width: 100%;
					border-radius: 15px;
					transition: all 0.2s ease-in-out;
					height: 45px;
					padding: 0 24px;
					font-weight: 600;
					font-size: 14px;
					line-height: 17px;
					letter-spacing: 0.2px;
					display: block;
					position: relative;

					&:hover {
						background: #2b2b2b;
					}
				}
			}
		}

		.register-create-account {
			span {
				color: #8f90a6;
				margin-right: 8px;
			}

			button {
				background: none;
			}
		}
	}
}
</style>