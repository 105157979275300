<template>
	<div class="panel-content-wrapper">
		<div class="panel-content-container">
			<vf-loading class="mx-auto mt-3" v-if="loading.bixbPrice"></vf-loading>

			<div class="calculator">
				<div class="calculator-content" v-if="!loading.bixbPrice">
					<div class="calculator-title">How much USD is your BIXB?</div>
					<form class="calculator-form" @submit.prevent="calcUsdAmount()">
						<vf-input id="bixb-input" class="" label="Bixb Amount:" placeholder="BIXB" type="tel" v-model="calculatorData.bixb" @input="calcUsdAmount"></vf-input>
						<vf-input id="usd-input" class="mt-3" label="USD Amount:" placeholder="USD" type="tel" v-model="calculatorData.usd" @input="calcBixbAmount"></vf-input>
						<vf-btn class="mt-3 calculator-btn" type="primary" :loading="loading.calculator">Calculate</vf-btn>
					</form>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			loading: {
				bixbPrice: false,
			},
			calculatorData: {
				bixb: '',
				usd: '',
			},
			bixbPrice: null,
		}
	},
	methods: {
		getBixbPrice() {
			/* call the api */
			this.loading.bixbPrice = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.User.user_token);

			/* make call to server */
			this.$http.post("general/getBixbPrice", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.bixbPrice = result.data.data.bixbPrice
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.bixbPrice = false;
				})
		},
		calcUsdAmount() {
			var usdAmount = Number(this.calculatorData.bixb) * Number(this.bixbPrice)
			this.calculatorData.usd = usdAmount;
		},
		calcBixbAmount() {
			var bixbAmount = Number(this.calculatorData.usd) / Number(this.bixbPrice)
			this.calculatorData.bixb = bixbAmount;
		}
	},
	created() {
		this.getBixbPrice();
	},
	mounted() {
		this.$root.$emit('setPanelTitleText', "Calculator");
	}
}
</script>

<style lang="scss">
@import "../../assets/styles/_typography";

.calculator {
	.calculator-content {
		width: 100%;
		max-width: 500px;
		background: #fff;
		padding: 24px;
		border-radius: 8px;
		margin: 0 auto;

		.calculator-title {
			@extend .tg-heading-4;
			text-align: center;
		}

		.wallet-details {
			width: 100%;
			max-width: 400px;
			margin: 0 auto;
			margin-top: 32px;

			.total-wallet-amount,
			.freezed-wallet-amount,
			.available-wallet-amount {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 12px;

				.amount {
					font-weight: 600;
				}
			}
		}

		.calculator-form {
			width: 100%;
			max-width: 500px;
			margin: 0 auto;
			margin-top: 32px;

			.calculator-btn {
				display: block;
				margin: 0 auto;
				margin-top: 32px;
				min-width: 200px;
			}
		}
	}
}
</style>