<template>
	<div class="chat-wrapper">
		<vf-chat>
			<vf-chat-header>
				<button class="back-btn" @click="$parent.deselectChat">
					<i class="fas fa-chevron-left"></i>
				</button>

				<div class="vf-chat-title">{{$parent.tickets[$parent.selectedChat].title}}</div>
				<div class="vf-chat-header-right">
					<vf-btn type="secondary" :disabled="$parent.tickets[$parent.selectedChat].status == 'closed'" :loading="loading.resolveTicket" @click="resolveTicket">
						<span v-if="$parent.tickets[$parent.selectedChat].status == 'closed'">Resolved</span>
						<span v-else>Resolve</span>
					</vf-btn>
				</div>
			</vf-chat-header>

			<vf-chat-messages>
				<vf-loading class="mx-auto mt-3" v-if="$parent.loading.chat" />

				<template v-else>
					<div class="vf-chat-message" v-for="(message , i) in $parent.selectedChatData.messages" :key="i" :class="[message.sender == 'user' ? 'right' : '']">
						<div class="vf-chat-message-content">
							<div class="vf-message-sender">
								<template v-if="message.sender == 'user'">You</template>
								<template v-else>Support Team</template>
							</div>
							<div class="vf-message-text">
								{{message.text}}

								<div class="ticket-files" v-if="message.attachments != null">
									<div class="ticket-file" v-for="(file , index) in message.attachments_files" :key="index" @click="openUrl(file.link)">
										<div class="file-icon-wrapper">
											<i class="fas fa-file"></i>
										</div>
										<div class="file-title">
											<span>{{file.title}}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="vf-chat-message-date">{{message.human_created_at}}</div>
					</div>
				</template>
			</vf-chat-messages>

			<vf-chat-footer>
				<div class="vf-chat-message-input">
					<textarea class="vf-chat-input" placeholder="Type your answer" v-model="replyTicketData.message"></textarea>
					<button class="vf-chat-attach-btn" @click.prevent="$refs.chat_file_input.click()">
						<i class="fas fa-paperclip"></i>
						<span v-if="replyTicketData.attachment != null">
							{{replyTicketData.attachment.name.substring(0,20)}}
							<span v-if="replyTicketData.attachment.percentCompleted < 100">({{replyTicketData.attachment.percentCompleted}}%)</span>
						</span>
						<span v-else>Attach File</span>
					</button>
					<input class="d-none" ref="chat_file_input" type="file" @change="handleChatFileUpload" />
				</div>
				<div class="vf-chat-footer-right">
					<vf-btn type="primary" class="vf-chat-send-btn" @click="replyTicket" :loading="loading.replyTicket" :disabled="replyTicketData.message == ''">
						<i class="fas fa-paper-plane"></i>
						Send
					</vf-btn>
				</div>
			</vf-chat-footer>
		</vf-chat>
	</div>
</template>

<script>
export default {
	data() {
		return {
			loading: {
				resolveTicket: false,
				replyTicket: false,
			},
			replyTicketData: {
				message: '',
				attachment: null,
			}
		}
	},
	methods: {
		openUrl(url) {
			window.open(url, "_blank");
		},
		resolveTicket() {
			/* call the api */
			this.loading.resolveTicket = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.User.user_token);
			data.append("ticket_id", this.$parent.selectedChatData.id);

			/* make call to server */
			this.$http.post("support/resolveTicket", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.$parent.tickets[this.$parent.selectedChat].status = 'closed';
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.resolveTicket = false;
				})
		},
		handleChatFileUpload(e) {
			var file = e.target.files[0];

			var fileIndex = this.replyTicketData.attachment = {
				name: file.name,
				size: file.size,
				type: file.type,
				finished: false,
				percentCompleted: 0,
				file_id: null,
			};

			var data = new FormData();
			data.append("token", this.$store.state.User.user_token);
			data.append('section', 'tickets');
			data.append('file', file);

			var config = {
				onUploadProgress: (progressEvent) => {
					var percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100);
					this.replyTicketData.attachment.percentCompleted = percentCompleted;
				}
			};

			this.$http.post('files/uploadFile', data, config)
				.then((response) => {
					var responseData = response.data.data;
					this.replyTicketData.attachment.file_id = responseData.file.id;
					this.replyTicketData.attachment.finished = true;
				})
				.catch((e) => {
					/* remove the file from ticket */
					this.replyTicketData.attachment = null;
				});
		},
		replyTicket() {
			/* call the api */
			this.loading.replyTicket = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.User.user_token);
			data.append("ticket_id", this.$parent.selectedChatData.id);
			data.append("message", this.replyTicketData.message);

			if(this.replyTicketData.attachment != null && this.replyTicketData.attachment.finished == true){
				data.append("attachments", this.replyTicketData.attachment.file_id);
			}

			/* make call to server */
			this.$http.post("support/replyTicket", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.resetForm();
						this.$parent.selectedChatData.messages.push(result.data.data.ticket_message);

						/* set ticket status to user answered */
						this.$parent.tickets[this.$parent.selectedChat].status = 'user_answered';

						/* scroll down */

					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.replyTicket = false;
				})
		},
		resetForm(){
			this.replyTicketData = {
				message: '',
				attachment: null,
			}
		}
	},
	watch:{
		'$parent.selectedChat': function(){
			this.resetForm();
		}
	}
}
</script>

<style lang="scss">
.chat-wrapper {
	height: 100%;
}

.ticket-files {
	margin-top: 16px;
	.ticket-file {
		display: flex;
		align-items: center;
		border: 1px solid #000;
		border-radius: 9px;
		padding: 10px;
		cursor: pointer;
		margin-bottom: 7px;
		&:last-child {
			margin-bottom: 0;
		}
		.file-icon-wrapper {
			width: 45px;
			height: 45px;
			background: #ffffff;
			border-radius: 9px;
			display: flex;
			justify-content: center;
			align-items: center;
			i {
				color: #000000;
				font-size: 24px;
			}
		}
		.file-title {
			margin-left: 12px;
		}
	}
}
</style>